/*=========================================
    Genereic components and elements
=========================================*/

/* ----------------- Tooltip */

.tooltip {
    /* Display & Box Model */
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: solid 0.1rem var(--color-secondary);
    margin: 0 0.5rem;

    /* Colors */
    /* background-color: var(--color-gray); */
    color: var(--color-secondary);

    /* Position */
    position: relative;

    /* Text */
    font-size: 1rem;
    font-weight: 300;

    /* Other */
    cursor: pointer;
}

.tooltip__description {
    /* Display & Box Model */
    width: 10rem;
    padding: 0.5rem;

    /* Color */
    background-color: rgba(0, 0, 0, 0.9);
    color: var(--color-text-in-black);

    /* Position */
    position: absolute;

    /* Text */
    font-size: 0.8rem;
    z-index: 2;
}

.tooltip__description--close {
    /* Display & Box Model */
    display: none;
}

.tooltip__description--open {
    /* Display & Box Model */
    display: inline-block;
}

.tooltip__position--left {
    /* Position */
    left: -10.5rem;
}

.tooltip__position--right {
    /* Position */
    right: -10.5rem;
}

.tooltip__position--top {
    /* Position */
    top: -3rem;
}

.tooltip__position--bottom {
    /* Position */
    bottom: -3rem;
}

/* ----------------- BackLink */

.backlink {
    /* Display & Box Model */
    width: fit-content;
    margin: 1rem 0;

    /* Color */
    background-color: var(--color-white);

    /* Position */
    position: relative;
    z-index: 90;

    /* Other */
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.backlink a {
    /* Display & Box Model */
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* Color */
    color: var(--color-text);
}

.backlink__icon {
    /* Display & Box Model */
    width: 3rem;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Color */
    background-color: var(--color-secondary);
}

.backlink__icon svg {
    fill: var(--color-white);
}

.backlink__text {
    /* Display & Box Model */
    padding: 0 0.5rem;
}

/* ----------------- Alert */

.alerts__title {
    /* Display & Box Model */
    margin-bottom: 0.5rem;

    /* Color */
    color: var(--color-title);

    /* Text */
    font-size: 1.6rem;
    font-weight: 300;
}

.alerts__fieldset {
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto;

    /* Text */
    font-size: 1rem;
}

.alerts__prices {
    /* Display & Box Model */
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin: 1rem auto;

    /* Position */
    position: relative;
}

.alerts__price {
    /* Color */
    color: var(--color-black);

    /* Position */
    position: relative;
}

.alerts__price > p {
    /* Display & Box Model */
    display: flex;
}

.alerts__price:nth-child(3) {
    /* Color */
    color: var(--color-primary);
}

.alerts__prices .input__field .input {
    /* Display & Box Model */
    width: 100%;
}

.alerts__prices p {
    /* Text */
    font-size: 0.8rem;

    /* Position */
    position: absolute;
    top: -0.8rem;
}

.alerts__prices label {
    /* Display & Box Model */
    display: flex;
}

.alerts__prices h6 {
    /* Display & Box Model */
    margin-top: 2rem;

    /* Text */
    font-size: 2rem;
}

.alerts__fieldset .fieldset__title {
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 0.5rem;
    display: flex;

    /* Color */
    color: var(--color-important-text);

    /* Text */
    font-weight: 700;
}

.alerts__fieldset .fieldset__description {
    /* Display & Box Model */
    border-bottom: solid 0.1rem var(--color-border);
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    display: block;
}

.alerts__fieldset p {
    /* Display & Box Model */
    margin: 1rem auto;
}

.alerts__fieldset mark {
    /* Display & Box Model */
    margin: 0 0.3rem;
    padding: 0 0.4rem 0 0.6rem;

    /* Color */
    color: var(--color-white);
    background-color: var(--color-primary);

    /* Text */
    text-align: center;
}

.alerts__tag--opened {
    /* Color */
    background-color: var(--color-secondary);
    color: var(--color-text-in-black);
    fill: var(--color-white);
}

.alerts__tag--new {
    /* Color */
    background-color: var(--color-secondary);
    color: var(--color-text-in-black);
    fill: var(--color-white);
}

.alerts__tag--sented {
    /* Color */
    background-color: var(--color-primary);
    color: var(--color-text-in-black);
    fill: var(--color-white);
}

.alerts__tag--rejected {
    /* Color */
    background-color: var(--color-red);
    color: var(--color-text-in-black);
    fill: var(--color-white);
}

.alerts__tag--expired {
    /* Color */
    background-color: var(--color-gray-medium);
    color: var(--color-text-in-black);
    fill: var(--color-gray);
}

@media (min-width: 800px) {
    .alerts__prices {
        /* Display & Box Model */
        display: grid;
        grid-template-columns: 25% 30% 30%;
        grid-gap: 1rem;
        margin: 1rem auto;

        /* Position */
        position: relative;
    }

    .alerts__prices .input__field .input {
        /* Display & Box Model */
        width: 6rem;
    }
}

/* ----------------- Texts */

.description {
    /* Display & Box Model */
    width: 100%;
    border: none;
    overflow-wrap: inherit;

    /* Color */
    background-color: transparent;
    color: var(--color-text);

    /* Text */
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 1.5;
}

/* ----------------- Header page component */

.headerpage {
    /* Display & Box Model */
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 1rem;
    /* border-bottom: solid 0.1rem var(--color-border); */
    margin: 1rem auto;
}

.headerpage__title {
    /* Display & Box Model */
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;

    /* Text */
    font-size: 1.8rem;
    font-weight: 300;
    text-align: center;

    /* Colors */
    color: var(--color-title);
}

.headerpage__subtitle {
    /* Text */
    font-size: 1rem;
    font-weight: 300;
    text-align: center;

    /* Colors */
    color: var(--color-text);
}

/*=========================================
    Supplier elements & components only
=========================================*/

/* ----------------- Supplier default grid page */

.supplier {
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 16% 84%;
}

.supplier .container {
    /* Display & Box Model */
    width: 90%;
}

/* ----------------- Supplier alerts content */

.alerts__content .alerts__tag {
    /* Display & Box Model */
    width: 5rem;
    height: 6rem;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.2rem;

    /* Text */
    font-size: 0.8rem;
    text-align: center;

    /* Position */
    position: absolute;
    top: -0.2rem;
    right: 1rem;
}

.alerts__content .alerts__tag span {
    /* Text */
    font-size: 2rem;
}

.alerts__content .alerts__tag svg {
    /* Display & Box Model */
    margin: 1rem auto;

    /* Others */
    transform: scale(1.5);
}

.supplier .alerts__date {
    /* Display & Box Model */
    margin-bottom: 2rem;

    /* Color */
    color: var(--color-primary);

    /* Text */
    font-size: 1rem;
    font-weight: 300;
}

/* ----------------- Supplier alerts card */

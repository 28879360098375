@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;600;700&display=swap);
/*=========================================
    Genereic components and elements
=========================================*/

/* ----------------- Tooltip */

.tooltip {
    /* Display & Box Model */
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: solid 0.1rem var(--color-secondary);
    margin: 0 0.5rem;

    /* Colors */
    /* background-color: var(--color-gray); */
    color: var(--color-secondary);

    /* Position */
    position: relative;

    /* Text */
    font-size: 1rem;
    font-weight: 300;

    /* Other */
    cursor: pointer;
}

.tooltip__description {
    /* Display & Box Model */
    width: 10rem;
    padding: 0.5rem;

    /* Color */
    background-color: rgba(0, 0, 0, 0.9);
    color: var(--color-text-in-black);

    /* Position */
    position: absolute;

    /* Text */
    font-size: 0.8rem;
    z-index: 2;
}

.tooltip__description--close {
    /* Display & Box Model */
    display: none;
}

.tooltip__description--open {
    /* Display & Box Model */
    display: inline-block;
}

.tooltip__position--left {
    /* Position */
    left: -10.5rem;
}

.tooltip__position--right {
    /* Position */
    right: -10.5rem;
}

.tooltip__position--top {
    /* Position */
    top: -3rem;
}

.tooltip__position--bottom {
    /* Position */
    bottom: -3rem;
}

/* ----------------- BackLink */

.backlink {
    /* Display & Box Model */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 1rem 0;

    /* Color */
    background-color: var(--color-white);

    /* Position */
    position: relative;
    z-index: 90;

    /* Other */
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.backlink a {
    /* Display & Box Model */
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* Color */
    color: var(--color-text);
}

.backlink__icon {
    /* Display & Box Model */
    width: 3rem;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Color */
    background-color: var(--color-secondary);
}

.backlink__icon svg {
    fill: var(--color-white);
}

.backlink__text {
    /* Display & Box Model */
    padding: 0 0.5rem;
}

/* ----------------- Alert */

.alerts__title {
    /* Display & Box Model */
    margin-bottom: 0.5rem;

    /* Color */
    color: var(--color-title);

    /* Text */
    font-size: 1.6rem;
    font-weight: 300;
}

.alerts__fieldset {
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto;

    /* Text */
    font-size: 1rem;
}

.alerts__prices {
    /* Display & Box Model */
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin: 1rem auto;

    /* Position */
    position: relative;
}

.alerts__price {
    /* Color */
    color: var(--color-black);

    /* Position */
    position: relative;
}

.alerts__price > p {
    /* Display & Box Model */
    display: flex;
}

.alerts__price:nth-child(3) {
    /* Color */
    color: var(--color-primary);
}

.alerts__prices .input__field .input {
    /* Display & Box Model */
    width: 100%;
}

.alerts__prices p {
    /* Text */
    font-size: 0.8rem;

    /* Position */
    position: absolute;
    top: -0.8rem;
}

.alerts__prices label {
    /* Display & Box Model */
    display: flex;
}

.alerts__prices h6 {
    /* Display & Box Model */
    margin-top: 2rem;

    /* Text */
    font-size: 2rem;
}

.alerts__fieldset .fieldset__title {
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 0.5rem;
    display: flex;

    /* Color */
    color: var(--color-important-text);

    /* Text */
    font-weight: 700;
}

.alerts__fieldset .fieldset__description {
    /* Display & Box Model */
    border-bottom: solid 0.1rem var(--color-border);
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    display: block;
}

.alerts__fieldset p {
    /* Display & Box Model */
    margin: 1rem auto;
}

.alerts__fieldset mark {
    /* Display & Box Model */
    margin: 0 0.3rem;
    padding: 0 0.4rem 0 0.6rem;

    /* Color */
    color: var(--color-white);
    background-color: var(--color-primary);

    /* Text */
    text-align: center;
}

.alerts__tag--opened {
    /* Color */
    background-color: var(--color-secondary);
    color: var(--color-text-in-black);
    fill: var(--color-white);
}

.alerts__tag--new {
    /* Color */
    background-color: var(--color-secondary);
    color: var(--color-text-in-black);
    fill: var(--color-white);
}

.alerts__tag--sented {
    /* Color */
    background-color: var(--color-primary);
    color: var(--color-text-in-black);
    fill: var(--color-white);
}

.alerts__tag--rejected {
    /* Color */
    background-color: var(--color-red);
    color: var(--color-text-in-black);
    fill: var(--color-white);
}

.alerts__tag--expired {
    /* Color */
    background-color: var(--color-gray-medium);
    color: var(--color-text-in-black);
    fill: var(--color-gray);
}

@media (min-width: 800px) {
    .alerts__prices {
        /* Display & Box Model */
        display: grid;
        grid-template-columns: 25% 30% 30%;
        grid-gap: 1rem;
        margin: 1rem auto;

        /* Position */
        position: relative;
    }

    .alerts__prices .input__field .input {
        /* Display & Box Model */
        width: 6rem;
    }
}

/* ----------------- Texts */

.description {
    /* Display & Box Model */
    width: 100%;
    border: none;
    overflow-wrap: inherit;

    /* Color */
    background-color: transparent;
    color: var(--color-text);

    /* Text */
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 1.5;
}

/* ----------------- Header page component */

.headerpage {
    /* Display & Box Model */
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 1rem;
    /* border-bottom: solid 0.1rem var(--color-border); */
    margin: 1rem auto;
}

.headerpage__title {
    /* Display & Box Model */
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;

    /* Text */
    font-size: 1.8rem;
    font-weight: 300;
    text-align: center;

    /* Colors */
    color: var(--color-title);
}

.headerpage__subtitle {
    /* Text */
    font-size: 1rem;
    font-weight: 300;
    text-align: center;

    /* Colors */
    color: var(--color-text);
}

/*=========================================
    Supplier elements & components only
=========================================*/

/* ----------------- Supplier default grid page */

.supplier {
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 16% 84%;
}

.supplier .container {
    /* Display & Box Model */
    width: 90%;
}

/* ----------------- Supplier alerts content */

.alerts__content .alerts__tag {
    /* Display & Box Model */
    width: 5rem;
    height: 6rem;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.2rem;

    /* Text */
    font-size: 0.8rem;
    text-align: center;

    /* Position */
    position: absolute;
    top: -0.2rem;
    right: 1rem;
}

.alerts__content .alerts__tag span {
    /* Text */
    font-size: 2rem;
}

.alerts__content .alerts__tag svg {
    /* Display & Box Model */
    margin: 1rem auto;

    /* Others */
    transform: scale(1.5);
}

.supplier .alerts__date {
    /* Display & Box Model */
    margin-bottom: 2rem;

    /* Color */
    color: var(--color-primary);

    /* Text */
    font-size: 1rem;
    font-weight: 300;
}

/* ----------------- Supplier alerts card */

/*=========================================
                Reset CSS
=========================================*/

/*------------------- Import font */

@font-face {
  font-family: 'Estate';
  src: url(/static/media/estate-medium.d47de519.woff2),
    url(/static/media/estate-medium.72806fc4.woff);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Estate';
  src: url(/static/media/estate-light.6ee133df.woff2),
    url(/static/media/estate-light.e47c8b6b.woff);
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Estate';
  src: url(/static/media/estate-bold.91a5d698.woff2),
    url(/static/media/estate-bold.93e8720d.woff);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body,
html {
  scroll-behavior: smooth;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Manrope', sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

a,
ul,
li,
ol,
p,
span {
  text-decoration: none;
  list-style-type: none;
}

a {
  color: #000;
  color: var(--color-black);
  font-weight: 600;
}

button {
  font-weight: 600;
}

a.disabled {
  pointer-events: none;
}

html,
body,
#root {
  height: 100vh;
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-background);
}

.row {
  margin-bottom: 0px;
}

p {
  white-space: pre-wrap;
}

/* section{
    margin: 4rem 0;
}

@media(min-width: 800px){
    section{
        margin: 4rem 0;
    }
} */

/*=========================================
            Generic Classes
=========================================*/

:root {
  /* --- Colors --- */
  --color-primary: #9614c3;
  --color-secondary: #ff2c55;
  --color-black: #000;
  --color-white: #fff;
  --color-gray-light: #f8f8f8;
  --color-gray: #d3d3d3;
  --color-gray-medium: #696969;
  --color-red: #E92929;
  --color-bg-404: linear-gradient(to bottom, #3a0910, #0c1426);
  --color-purple-gradient: linear-gradient(-120deg, #39084a, #000000);
  --color-purple-gradient-auth-pages: linear-gradient(45deg, #39084a, rgba(0, 0, 0, 0.8));
  --color-pink-gradient: linear-gradient(45deg, #700e21, #000000);
  --color-slim-button: rgba(255, 255, 255, 0.2);
  /* --- Text Colors --- */
  --color-title: #000;
  --color-title-in-black: #fff;
  --color-text: #696969;
  --color-text-in-black: #ffffff;
  --color-important-text: #000000;
  --color-warning-text: #c0392b;
  --color-border: #696969;
  --color-border-in-black: #d3d3d3;
  --color-border: #d3d3d3;
  /* --- Others --- */
  --shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  --text-shadow: 0 0 0.5rem rgba(255, 255, 255, 0.8);
  --radius: 0.3rem;


  /*--- NEW BRANDING ---*/
  --color-primary-light: #7A29F2;
  --color-primary-dark: #4F0099;
  --color-green: #06C0A2;
  --color-purple-dark: #CF2890;
  --color-purple-light: #FF0079;
  --color-blue-dark: #1C5AC3;
  --color-blue-light: #787ADD;
  --color-yellow-dark: #FF960A;
  --color-yellow-light: #FFD10D;
  --color-yellow-light: #FFD10D;
  --color-gray-light-2: #E6E6E6;
  --color-red: #E92929;
  --color-ice: #F8F8F8;
}

/*------------------ Background Colors */

.bg--primary {
  background-color: #7A29F2 !important;
  background-color: var(--color-primary-light) !important;
}
.bg--primary-dark {
  background-color: #4F0099 !important;
  background-color: var(--color-primary-dark) !important;
}

.bg--complamentary {
  background-color: var(--color-primary-complementary) !important;
}

.bg--secondary {
  background-color: #ff2c55 !important;
  background-color: var(--color-secondary) !important;
}

.bg--blue {
  background-color: var(--color-blue) !important;
}

.bg--green {
  background-color: #06C0A2 !important;
  background-color: var(--color-green) !important;
}

.bg--red {
  background-color: #E92929 !important;
  background-color: var(--color-red) !important;
}

.bg--black {
  background-color: #000 !important;
  background-color: var(--color-black) !important;
}

.bg--white {
  background-color: #fff !important;
  background-color: var(--color-white) !important;
}

.bg--ice{
  background-color: #F8F8F8 !important;
  background-color: var(--color-ice) !important;
}

.bg--gray {
  background-color: #d3d3d3 !important;
  background-color: var(--color-gray) !important;
}

.bg--gray-dark {
  background-color: var(--color-gray-dark) !important;
}

.bg--gray-light {
  background-color: #f8f8f8 !important;
  background-color: var(--color-gray-light) !important;
}

/*------------------ Text Colors */

.txt--primary {
  color: #9614c3;
  color: var(--color-primary);
  fill: #9614c3;
  fill: var(--color-primary);
}
.txt--primary-dark {
  color: #4F0099;
  color: var(--color-primary-dark);
  fill: #4F0099;
  fill: var(--color-primary-dark);
}

.txt--secondary {
  color: #ff2c55;
  color: var(--color-secondary);
  fill: #ff2c55;
  fill: var(--color-secondary);
}

.txt--blue {
  color: var(--color-blue);
  fill: var(--color-blue);
}

.txt--green {
  color: #06C0A2;
  color: var(--color-green);
  fill: #06C0A2;
  fill: var(--color-green);
}

.txt--red {
  color: #E92929;
  color: var(--color-red);
  fill: #E92929;
  fill: var(--color-red);
}

.txt--black {
  color: #000;
  color: var(--color-black);
  fill: #000;
  fill: var(--color-black);
}

.txt--gray {
  color: #d3d3d3;
  color: var(--color-gray);
  fill: #d3d3d3;
  fill: var(--color-gray);
}

.txt--dark-gray {
  color: var(--color-dark-gray);
  fill: var(--color-dark-gray);
}

.txt--ice {
  color: #F8F8F8;
  color: var(--color-ice);
  fill: #F8F8F8;
  fill: var(--color-ice);
}

.txt--white {
  color: #fff;
  color: var(--color-white);
  fill: #fff;
  fill: var(--color-white);
}

.txt--default {
  color: #696969;
  color: var(--color-text);
}

/*=============================
    Text Align and Sizes
=============================*/

.txt--center {
  text-align: center !important;
}

.txt--left {
  text-align: left !important;
}

.txt--bold {
  font-weight: 700;
}

.txt--light {
  font-weight: 300;
}

mark {
  padding: 0px 5px;
  background-color: var(--light);
  color: var(--dark-gray);
  border-radius: 5px;
  font-size: 0.9em;
}

/*------------------ Text Sizes */

.title {
  font-size: 3em;
  font-weight: 700;
  margin: 20px 0px;
}

.title--sub {
  font-size: 2.5em;
  font-weight: 300;
  margin: 10px 0px 30px 0px;
}

.title--section {
  /* Display & Box Model */
  margin-bottom: 2rem;

  /* Text */
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
}

.subtitle {
  font-size: 1.8em;
  font-weight: 300;
  margin: 10px 0px 30px 0px;
}

.subtitle--section {
  width: 100%;
  text-align: center;
  margin: auto;
  margin-bottom: 1rem;
}

@media (min-width: 800px) {
  .subtitle--section {
    width: 70%;
    margin-bottom: 3rem;
  }
}

.txt--bold {
  font-weight: 700;
}

.txt--slim {
  font-weight: 300;
}

/*------------------ Aligns */

.align__content--center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: left;
  height: 100%;
}

.center {
  margin-left: auto !important;
  margin-right: auto !important;
}

/*------------------ Margins */

.margin--top-1 {
  margin-top: 1rem;
}

.margin--top-2 {
  margin-top: 2rem;
}

.margin--bottom-1 {
  margin-bottom: 1rem;
}

.margin--bottom-2 {
  margin-bottom: 2rem;
}

.margin--tb-1 {
  margin: 1rem 0;
}

.margin--tb-2 {
  margin: 2rem 0;
}

.margin--lr-1 {
  margin: 0 1rem;
}

.margin--lr-2 {
  margin: 0 2rem;
}

.margin--auto {
  margin: auto;
}

/*------------------ Padings */

.padding--top-1 {
  padding-top: 1rem;
}

.padding--top-2 {
  padding-top: 2rem;
}

.padding--bottom-1 {
  padding-bottom: 1rem;
}

.padding--bottom-2 {
  padding-bottom: 2rem;
}

.padding--tb-1 {
  padding: 1rem 0;
}

.padding--tb-2 {
  padding: 2rem 0;
}

.padding--lr-1 {
  padding: 0 1rem;
}

.padding--lr-2 {
  padding: 0 2rem;
}

.padding--auto {
  padding: auto;
}

/*------------------ Buttons */

/* Default button */

.btn {
  /* Display & Box Model */
  min-width: 14rem;
  margin: 1rem 0;
  border: none;
  display: block;

  /* Color */
  background-color: #9614c3;
  background-color: var(--color-primary);
  /* color: var(--color-text-in-black); */

  /* Text */
  font-family: 'Pt Sans', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;

  /* Position */
  position: relative;
  /* z-index: 9; */

  /* Others */
  transform: skewX(-8deg);
  cursor: pointer;
  transition: all 0.3s ease-out;
  border-radius: 0.2rem;
}

@media only print {
  .btn {
    display: none;
  }
}

.btn--larger {
  /* Display & Box Model */
  max-width: 450px;
}

.btn--small {
  /* Others */
  transform: scale(0.7) skew(-8deg);
}

.btn--center {
  /* Display & Box Model */
  margin: 1rem auto;
}

.btn--fullwidth {
  /* Display & Box Model */
  min-width: 100% !important;
}

.btn span,
.btn a {
  /* Display & Box Model */
  min-height: 3rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  /* Position */
  position: relative;
  z-index: 1;

  /* Colors */
  color: #fff;
  color: var(--color-white);

  /* Others */
  transition: all 0.3s ease-out;
  transform: skewX(8deg);
}

.btn label {
  /* Others */
  cursor: pointer;
}

/*
.btn::after, .btn::before{
    /* Display & Box Model 
    content: '';
    width: 100%;
    min-height: 3rem;
    display: block;
    border: solid 0.1rem var(--color-secondary);
    
    /* Colors 
    background-color: transparent;
    
    /* Position 
    position: absolute;
    z-index: -1;
    
    /* Others 
    transition: all 0.3s ease-out;
    opacity: 0;
}
*/

/*
.btn:hover::after, .btn:hover::before{
    /* Others 
    opacity: 1;
}

.btn::before{
    /* Position 
    top: -0.5rem;
    left: -0.5rem;
}


.btn::after{
    /* Position 
    top: 0.3rem;
    left: 0.3rem;
}
*/

.btn:active {
  /* Display & Box Model */
  border-color: transparent;
  outline: none;
}

.btn:disabled {
  /* Others */
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.7;
  cursor: no-drop;
}

.btn:disabled span,
.btn:disabled a {
  /* Others */
  cursor: no-drop;
}

/* Slim button */

.btn--slim {
  /* Display & Box Model */
  border: solid 0.1rem #fff;
  border: solid 0.1rem var(--color-white);

  /* Colors */
  background-color: rgba(255, 255, 255, 0.2);
  background-color: var(--color-slim-button);
}

.btn--slim::before {
  /* Display & Box Model */
  display: none;
}

.btn--slim::after {
  /* Display & Box Model */
  display: none;
}

.btn--slim a,
.btn--slim span {
  /* Colors */
  color: #d3d3d3;
  color: var(--color-gray);

  /* Position */
  top: 0;
  left: 0;
}

.btn--slim:hover {
  background-color: #9614c3;
  background-color: var(--color-primary);
  border: solid 0.1rem #9614c3;
  border: solid 0.1rem var(--color-primary);
}

.btn--slim[class~='txt--red'] {
  /* Colros */
  color: #E92929;
  color: var(--color-red);
}

.btn--slim[class~='txt--gray'] {
  /* Colros */
  color: #696969;
  color: var(--color-gray-medium);

  /* Others */
  border: solid 0.1rem #696969;
  border: solid 0.1rem var(--color-gray-medium);
}

.btn--slim[class~='txt--gray'] a,
.btn--slim[class~='txt--gray'] span {
  /* Colors */
  color: #696969;
  color: var(--color-gray-medium);
}

.btn--slim[class~='txt--secondary'] {
  color: #ff2c55;
  color: var(--color-secondary);
}

.btn--slim:hover a,
.btn--slim:hover span {
  top: 0;
  color: #fff;
  color: var(--color-white);
}

/* Warning button */

.btn--warning {
  /* Colors */
  background-color: #E92929;
  background-color: var(--color-red);
}

.btn--border {
  border: solid 1px;
}

.btn--border a {
  color: var(--gray);
}

.btn--helper {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  box-shadow: 0px 2px 5px var(--gray);
  background-color: var(--color-yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (min-width: 800px) {
  .btn {
    max-width: 300px;
  }

  .btn--larger {
    /* Display & Box Model */
    max-width: 500px;
  }
}

/*------------------------------- Buttons secondary */

.btn--secondary {
  background-color: transparent;
  border: solid 2px #ff2c55;
  border: solid 2px var(--color-secondary);
}

.btn--secondary::after {
  content: '';
  display: none;
}

.btn--secondary a,
.btn--secondary span {
  color: #ff2c55;
  color: var(--color-secondary);
  top: 0;
  left: 0;
  padding: 0 2rem;
}

.btn--secondary[class~='bg--black']::after {
  background-color: #000;
  background-color: var(--color-black);
}

.btn--secondary[class~='bg--white']::after {
  background-color: #fff;
  background-color: var(--color-white);
}

.btn--secondary[class~='bg--white'] span,
.btn--secondary[class~='bg--white'] a {
  color: #000;
  color: var(--color-black);
}

/*------------------------------- Buttons with icon */

.btn--icon {
  /* Display & Box Model */
  border: solid 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Color */
  background-color: transparent;
}

.btn--icon::after {
  /* Display & Box Model */
  content: '';
  display: none;
}

.btn--icon span,
.btn--icon a {
  /* Color */
  color: inherit;

  /* Position */
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;

  /* Others */
  transition: all 0.3s ease-out;
}

.btn--icon svg {
  /* Display & Box Model */
  margin: auto 1rem;
}

/*=============================
        Scroll styled
=============================*/

/* width */

::-webkit-scrollbar {
  /* Display & Box Model */
  width: 0.8rem;
}

/* Track */

::-webkit-scrollbar-track {
  background-color: #f8f8f8;
  background-color: var(--color-gray-light);
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  background: var(--color-gray);
  border: solid 3px #d3d3d3;
  border: solid 3px var(--color-gray);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #696969;
  background: var(--color-gray-medium);
  border: none;
}

/*=============================
            Forms
=============================*/

.form {
  width: 100%;
  padding: 20px 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  box-shadow: var(--shadow);
  background-color: var(--white);
  border-radius: 10px;
}

.form section {
  height: auto;
  transition: all 0.2s ease-out;
  opacity: 1;
}

.form .form__hidden {
  height: 0px;
  overflow: hidden;
  opacity: 0;
}

.form fieldset {
  border: none;
  margin: 20px auto;
}

.form .fieldset {
  border: solid 1px #d3d3d3;
  border-radius: 5px;
}

.form--item {
  /* margin-top: -15%; */
  z-index: 9;
}

.form .btn {
  margin: auto 10px;
}

.form--item .btn {
  margin: auto;
}

.form__btns {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form__btns .btn {
  margin: 10px;
}

.form__btns .btn[class~='txt--red'] a {
  color: var(--red);
}

@media (min-width: 800px) {
  .form__btns {
    flex-direction: row;
  }
}

/*----------------------------- Inputs*/

#carte-items li {
  padding: 10px 15px;
  border: solid 1px #d3d3d3;
  border: solid 1px var(--color-border);
  color: #696969;
  color: var(--color-text);
}

.input__field {
  width: 100%;
  position: relative;
  margin: 20px auto;
}

@-webkit-keyframes inputErrorAnime {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }

  10% {
    opacity: 1;
    transform: translateY(0);
  }

  95% {
    opacity: 1;
    transform: translateY(0);
  }

  99% {
    opacity: 0;
    transform: translateY(-1rem);
  }
}

@keyframes inputErrorAnime {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }

  10% {
    opacity: 1;
    transform: translateY(0);
  }

  95% {
    opacity: 1;
    transform: translateY(0);
  }

  99% {
    opacity: 0;
    transform: translateY(-1rem);
  }
}

.input__field--error::after {
  content: 'Preencha este campo corretamente';
  color: #E92929;
  color: var(--color-red);
  position: absolute;
  bottom: -1rem;
  font-size: 0.8rem;
  opacity: 0;
  -webkit-animation: inputErrorAnime 5s ease-out 0s forwards;
          animation: inputErrorAnime 5s ease-out 0s forwards;
}

.input__field label {
  position: absolute;
  top: -15px;
  color: #696969;
  color: var(--color-text);
  font-size: 0.8rem;
  align-items: center;
}

span[class~='subtitle'] {
  display: block !important;
}

.input {
  /* Display & Box Model */
  width: 100%;
  height: 3rem;
  padding: 0px 15px;
  margin: 10px;
  margin-left: 0px;

  /* Colors */
  color: #000;
  color: var(--color-black);
  background-color: #fff;
  background-color: var(--color-white);

  /* Text */
  font-size: 1em;
  font-family: 'Pt Sans', sans-serif;

  /* Others */
  border: none;
  border-radius: 0.2rem;
  transition: all 0.3s ease-out;
  box-sizing: border-box;
  border: solid 0.1rem #d3d3d3;
  border: solid 0.1rem var(--color-gray);
}

.input:active {
  outline: none;
  border: none;
}

.input--hidden {
  border: none;
  width: 100%;
  padding: 0px;
}

.input--error {
  -webkit-animation: input-error 0.3s linear forwards;
          animation: input-error 0.3s linear forwards;
  /* position: relative; */
  border-bottom: 2px solid #E92929;
  border-bottom: 2px solid var(--color-red);
}

.input__days {
  position: absolute;
  top: 12px;
  right: 1px;
  color: #696969;
  color: var(--color-text);
  background-color: var(--color-background);
  display: flex;
  width: 80px;
  height: 45px;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes input-error {
  0% {
    transform: translateX(-5px);
  }

  25% {
    transform: translateX(5px);
  }

  50% {
    transform: translateX(-5px);
  }

  75% {
    transform: translateX(5px);
  }

  100% {
    /* border: 1px solid var(--color-red); */
    transform: translateX(0px);
  }
}

@keyframes input-error {
  0% {
    transform: translateX(-5px);
  }

  25% {
    transform: translateX(5px);
  }

  50% {
    transform: translateX(-5px);
  }

  75% {
    transform: translateX(5px);
  }

  100% {
    /* border: 1px solid var(--color-red); */
    transform: translateX(0px);
  }
}

.input:focus {
  outline: #9614c3;
  outline: var(--color-primary);
  border: solid 0.1rem #9614c3;
  border: solid 0.1rem var(--color-primary);
}

.input:hover {
  box-shadow: 0px 1px 5px var(--bg-light-gray);
}

.input:disabled {
  cursor: not-allowed;
}

@media only screen and (max-width: 600px) {
  .input {
    width: 100%;
  }
}

@media (min-width: 800px) {
  .form {
    padding: 30px;
  }

  /* .form--item {
        margin: -12% auto 50px auto;
    } */
}

.input__field--checkbox {
  margin: 1rem auto 2rem auto;
  text-align: left;
}

/*----------------------------- Checkbox*/

.checkbox {
  appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  -moz-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-sizing: border-box;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05); */
  padding: 9px;
  margin-top: 2px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.checkbox:checked {
  /* background-color: var(--purple); */
  border-color: var(--purple);
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1); */
  /* color: var(--purple); */
}

.checkbox:disabled {
  opacity: 0.5;
  background-color: var(--gray);
}

.checkbox:checked:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: var(--purple);
}

.input__field .checkbox__label {
  top: 0;
  left: 2rem;
  cursor: pointer;
  font-size: 0.8rem;
}

.checkbox__label a {
  /* Text */
  text-decoration: underline;
}

/*----------------------------- Radio */


/*----------------------------- Textarea */

.textarea {
  /* Display & Box Model */
  height: 200px;
  min-height: 80px;
  padding: 10px;

  /* Colors */
  color: var(--dark-gray);

  /* Text */
  line-height: 1.5;

  /* Others */
  overflow: scroll;
  overflow-x: hidden;
  resize: vertical;
  border: solid 0.1rem #d3d3d3;
  border: solid 0.1rem var(--color-gray);
}

.textarea:disabled {
  /* Display & Box Model */
  height: auto;

  /* Others */
  border: none;
}


/*=========================================
                Modal
=========================================*/

.box {
  width: 100%;
  background-color: #fff;
  background-color: var(--color-white);
  border-radius: 0.2rem;
  padding: 20px 30px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  box-shadow: var(--shadow);
}

.modal {
  width: 80%;
  position: fixed;
  z-index: 999;
  display: none;
  padding: 20px 30px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 15px var(--dark-gray);
}

.modal--price {
  width: 400px;
}

.modal__close {
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 10px var(--gray);
  font-size: 2.5em;
  cursor: pointer;
}

.modal__content {
  padding: 20px;
  max-height: 80vh;
}

.modal--hidden .modal__content {
  overflow-y: scroll;
}

.modal--open {
  display: block;
}

.modal__title {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 10px;
  color: #000;
  color: var(--color-black);
}

.modal__text {
  color: var(--gray);
  margin-bottom: 15px;
}

.modal__info {
  font-size: 0.9em;
  margin-top: 15px;
}

.lightbox {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 998;
  display: none;
  justify-content: center;
  align-items: center;
}

.lightbox--open {
  display: flex;
}

.lightbox__close {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
}

.fullscreen {
  width: 100%;
  min-height: 100vh;
}

.section {
  margin: 60px 0px;
}

@media only screen and (max-width: 600px) {
  .fullscreen {
    height: auto;
  }
}
@media print{
  #adopt-controller-button{
    display: none;
  }
}
@media print{
  @page { size: auto;  margin: 0mm;}
}
/*=========================================
                Grid Style
=========================================*/

.row {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.row .col {
    float: left;
    box-sizing: border-box;
    /* padding: 0 0.75rem; */
    padding-right: 1.5rem;
    min-height: 1px;
}

@media (max-width: 600px) {
    .row .col {
        padding: 0;
    }
}

.row .col[class*="push-"],
.row .col[class*="pull-"] {
    position: relative;
}

.row .col.s1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.row .col.s2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.row .col.s3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.row .col.s4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.row .col.s5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.row .col.s6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.row .col.s7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.row .col.s8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.row .col.s9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.row .col.s10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.row .col.s11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.row .col.s12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.row .col.offset-s1 {
    margin-left: 8.3333333333%;
}

.row .col.pull-s1 {
    right: 8.3333333333%;
}

.row .col.push-s1 {
    left: 8.3333333333%;
}

.row .col.offset-s2 {
    margin-left: 16.6666666667%;
}

.row .col.pull-s2 {
    right: 16.6666666667%;
}

.row .col.push-s2 {
    left: 16.6666666667%;
}

.row .col.offset-s3 {
    margin-left: 25%;
}

.row .col.pull-s3 {
    right: 25%;
}

.row .col.push-s3 {
    left: 25%;
}

.row .col.offset-s4 {
    margin-left: 33.3333333333%;
}

.row .col.pull-s4 {
    right: 33.3333333333%;
}

.row .col.push-s4 {
    left: 33.3333333333%;
}

.row .col.offset-s5 {
    margin-left: 41.6666666667%;
}

.row .col.pull-s5 {
    right: 41.6666666667%;
}

.row .col.push-s5 {
    left: 41.6666666667%;
}

.row .col.offset-s6 {
    margin-left: 50%;
}

.row .col.pull-s6 {
    right: 50%;
}

.row .col.push-s6 {
    left: 50%;
}

.row .col.offset-s7 {
    margin-left: 58.3333333333%;
}

.row .col.pull-s7 {
    right: 58.3333333333%;
}

.row .col.push-s7 {
    left: 58.3333333333%;
}

.row .col.offset-s8 {
    margin-left: 66.6666666667%;
}

.row .col.pull-s8 {
    right: 66.6666666667%;
}

.row .col.push-s8 {
    left: 66.6666666667%;
}

.row .col.offset-s9 {
    margin-left: 75%;
}

.row .col.pull-s9 {
    right: 75%;
}

.row .col.push-s9 {
    left: 75%;
}

.row .col.offset-s10 {
    margin-left: 83.3333333333%;
}

.row .col.pull-s10 {
    right: 83.3333333333%;
}

.row .col.push-s10 {
    left: 83.3333333333%;
}

.row .col.offset-s11 {
    margin-left: 91.6666666667%;
}

.row .col.pull-s11 {
    right: 91.6666666667%;
}

.row .col.push-s11 {
    left: 91.6666666667%;
}

.row .col.offset-s12 {
    margin-left: 100%;
}

.row .col.pull-s12 {
    right: 100%;
}

.row .col.push-s12 {
    left: 100%;
}

@media only screen and (min-width: 601px) {
    .row .col.m1 {
        width: 8.3333333333%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.m2 {
        width: 16.6666666667%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.m3 {
        width: 25%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.m4 {
        width: 33.3333333333%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.m5 {
        width: 41.6666666667%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.m6 {
        width: 50%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.m7 {
        width: 58.3333333333%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.m8 {
        width: 66.6666666667%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.m9 {
        width: 75%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.m10 {
        width: 83.3333333333%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.m11 {
        width: 91.6666666667%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.m12 {
        width: 100%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.offset-m1 {
        margin-left: 8.3333333333%;
    }

    .row .col.pull-m1 {
        right: 8.3333333333%;
    }

    .row .col.push-m1 {
        left: 8.3333333333%;
    }

    .row .col.offset-m2 {
        margin-left: 16.6666666667%;
    }

    .row .col.pull-m2 {
        right: 16.6666666667%;
    }

    .row .col.push-m2 {
        left: 16.6666666667%;
    }

    .row .col.offset-m3 {
        margin-left: 25%;
    }

    .row .col.pull-m3 {
        right: 25%;
    }

    .row .col.push-m3 {
        left: 25%;
    }

    .row .col.offset-m4 {
        margin-left: 33.3333333333%;
    }

    .row .col.pull-m4 {
        right: 33.3333333333%;
    }

    .row .col.push-m4 {
        left: 33.3333333333%;
    }

    .row .col.offset-m5 {
        margin-left: 41.6666666667%;
    }

    .row .col.pull-m5 {
        right: 41.6666666667%;
    }

    .row .col.push-m5 {
        left: 41.6666666667%;
    }

    .row .col.offset-m6 {
        margin-left: 50%;
    }

    .row .col.pull-m6 {
        right: 50%;
    }

    .row .col.push-m6 {
        left: 50%;
    }

    .row .col.offset-m7 {
        margin-left: 58.3333333333%;
    }

    .row .col.pull-m7 {
        right: 58.3333333333%;
    }

    .row .col.push-m7 {
        left: 58.3333333333%;
    }

    .row .col.offset-m8 {
        margin-left: 66.6666666667%;
    }

    .row .col.pull-m8 {
        right: 66.6666666667%;
    }

    .row .col.push-m8 {
        left: 66.6666666667%;
    }

    .row .col.offset-m9 {
        margin-left: 75%;
    }

    .row .col.pull-m9 {
        right: 75%;
    }

    .row .col.push-m9 {
        left: 75%;
    }

    .row .col.offset-m10 {
        margin-left: 83.3333333333%;
    }

    .row .col.pull-m10 {
        right: 83.3333333333%;
    }

    .row .col.push-m10 {
        left: 83.3333333333%;
    }

    .row .col.offset-m11 {
        margin-left: 91.6666666667%;
    }

    .row .col.pull-m11 {
        right: 91.6666666667%;
    }

    .row .col.push-m11 {
        left: 91.6666666667%;
    }

    .row .col.offset-m12 {
        margin-left: 100%;
    }

    .row .col.pull-m12 {
        right: 100%;
    }

    .row .col.push-m12 {
        left: 100%;
    }
}

@media only screen and (min-width: 993px) {
    .row .col.l1 {
        width: 8.3333333333%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.l2 {
        width: 16.6666666667%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.l3 {
        width: 25%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.l4 {
        width: 33.3333333333%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.l5 {
        width: 41.6666666667%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.l6 {
        width: 50%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.l7 {
        width: 58.3333333333%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.l8 {
        width: 66.6666666667%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.l9 {
        width: 75%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.l10 {
        width: 83.3333333333%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.l11 {
        width: 91.6666666667%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.l12 {
        width: 100%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.offset-l1 {
        margin-left: 8.3333333333%;
    }

    .row .col.pull-l1 {
        right: 8.3333333333%;
    }

    .row .col.push-l1 {
        left: 8.3333333333%;
    }

    .row .col.offset-l2 {
        margin-left: 16.6666666667%;
    }

    .row .col.pull-l2 {
        right: 16.6666666667%;
    }

    .row .col.push-l2 {
        left: 16.6666666667%;
    }

    .row .col.offset-l3 {
        margin-left: 25%;
    }

    .row .col.pull-l3 {
        right: 25%;
    }

    .row .col.push-l3 {
        left: 25%;
    }

    .row .col.offset-l4 {
        margin-left: 33.3333333333%;
    }

    .row .col.pull-l4 {
        right: 33.3333333333%;
    }

    .row .col.push-l4 {
        left: 33.3333333333%;
    }

    .row .col.offset-l5 {
        margin-left: 41.6666666667%;
    }

    .row .col.pull-l5 {
        right: 41.6666666667%;
    }

    .row .col.push-l5 {
        left: 41.6666666667%;
    }

    .row .col.offset-l6 {
        margin-left: 50%;
    }

    .row .col.pull-l6 {
        right: 50%;
    }

    .row .col.push-l6 {
        left: 50%;
    }

    .row .col.offset-l7 {
        margin-left: 58.3333333333%;
    }

    .row .col.pull-l7 {
        right: 58.3333333333%;
    }

    .row .col.push-l7 {
        left: 58.3333333333%;
    }

    .row .col.offset-l8 {
        margin-left: 66.6666666667%;
    }

    .row .col.pull-l8 {
        right: 66.6666666667%;
    }

    .row .col.push-l8 {
        left: 66.6666666667%;
    }

    .row .col.offset-l9 {
        margin-left: 75%;
    }

    .row .col.pull-l9 {
        right: 75%;
    }

    .row .col.push-l9 {
        left: 75%;
    }

    .row .col.offset-l10 {
        margin-left: 83.3333333333%;
    }

    .row .col.pull-l10 {
        right: 83.3333333333%;
    }

    .row .col.push-l10 {
        left: 83.3333333333%;
    }

    .row .col.offset-l11 {
        margin-left: 91.6666666667%;
    }

    .row .col.pull-l11 {
        right: 91.6666666667%;
    }

    .row .col.push-l11 {
        left: 91.6666666667%;
    }

    .row .col.offset-l12 {
        margin-left: 100%;
    }

    .row .col.pull-l12 {
        right: 100%;
    }

    .row .col.push-l12 {
        left: 100%;
    }
}

@media only screen and (min-width: 1201px) {
    .row .col.xl1 {
        width: 8.3333333333%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.xl2 {
        width: 16.6666666667%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.xl3 {
        width: 25%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.xl4 {
        width: 33.3333333333%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.xl5 {
        width: 41.6666666667%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.xl6 {
        width: 50%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.xl7 {
        width: 58.3333333333%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.xl8 {
        width: 66.6666666667%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.xl9 {
        width: 75%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.xl10 {
        width: 83.3333333333%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.xl11 {
        width: 91.6666666667%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.xl12 {
        width: 100%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    .row .col.offset-xl1 {
        margin-left: 8.3333333333%;
    }

    .row .col.pull-xl1 {
        right: 8.3333333333%;
    }

    .row .col.push-xl1 {
        left: 8.3333333333%;
    }

    .row .col.offset-xl2 {
        margin-left: 16.6666666667%;
    }

    .row .col.pull-xl2 {
        right: 16.6666666667%;
    }

    .row .col.push-xl2 {
        left: 16.6666666667%;
    }

    .row .col.offset-xl3 {
        margin-left: 25%;
    }

    .row .col.pull-xl3 {
        right: 25%;
    }

    .row .col.push-xl3 {
        left: 25%;
    }

    .row .col.offset-xl4 {
        margin-left: 33.3333333333%;
    }

    .row .col.pull-xl4 {
        right: 33.3333333333%;
    }

    .row .col.push-xl4 {
        left: 33.3333333333%;
    }

    .row .col.offset-xl5 {
        margin-left: 41.6666666667%;
    }

    .row .col.pull-xl5 {
        right: 41.6666666667%;
    }

    .row .col.push-xl5 {
        left: 41.6666666667%;
    }

    .row .col.offset-xl6 {
        margin-left: 50%;
    }

    .row .col.pull-xl6 {
        right: 50%;
    }

    .row .col.push-xl6 {
        left: 50%;
    }

    .row .col.offset-xl7 {
        margin-left: 58.3333333333%;
    }

    .row .col.pull-xl7 {
        right: 58.3333333333%;
    }

    .row .col.push-xl7 {
        left: 58.3333333333%;
    }

    .row .col.offset-xl8 {
        margin-left: 66.6666666667%;
    }

    .row .col.pull-xl8 {
        right: 66.6666666667%;
    }

    .row .col.push-xl8 {
        left: 66.6666666667%;
    }

    .row .col.offset-xl9 {
        margin-left: 75%;
    }

    .row .col.pull-xl9 {
        right: 75%;
    }

    .row .col.push-xl9 {
        left: 75%;
    }

    .row .col.offset-xl10 {
        margin-left: 83.3333333333%;
    }

    .row .col.pull-xl10 {
        right: 83.3333333333%;
    }

    .row .col.push-xl10 {
        left: 83.3333333333%;
    }

    .row .col.offset-xl11 {
        margin-left: 91.6666666667%;
    }

    .row .col.pull-xl11 {
        right: 91.6666666667%;
    }

    .row .col.push-xl11 {
        left: 91.6666666667%;
    }

    .row .col.offset-xl12 {
        margin-left: 100%;
    }

    .row .col.pull-xl12 {
        right: 100%;
    }

    .row .col.push-xl12 {
        left: 100%;
    }
}

.container {
    margin: 0 auto;
    max-width: 1200px;
    width: 90%;
    position: relative;
}

.container--form {
    width: 90%;
    margin: 0 auto 20px auto;
    position: relative;
    top: calc(10vh - 100px);
}

@media only screen and (min-width: 800px) {
    .container--form {
        max-width: 770px;
        margin: 0 10% 20px auto;
    }

    .container {
        width: 85%;
    }
}

@media only screen and (max-width: 601px) {
    .container {
        width: 85%;
    }
}

@media only screen and (min-width: 1400px) {
    .container {
        width: 80%;
        max-width: 1500px;
    }
}
